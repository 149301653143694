$primary-light: #3377FF;
$on-primary-light: #ffffff;
$primary-container-light: #c5e7ff;
$on-Primary-container-light: #001e2e;
$secondary-light: #b80070;
$on-secondary-light: #ffffff;
$secondary-container-light: #ffd8e6;
$on-secondary-container-light: #3e0021;
$tertiary-light: #62597c;
$on-tertiary-light: #ffffff;
$tertiary-container-light: #e9ddff;
$on-tertiary-container-light: #1e1635;
$error-light: #ba1b1b;
$error-container-light: #ffdad4;
$on-error-light: #ffffff;
$on-error-container-light: #410001;
$background-light: #fbfcff;
$on-background-light: #191c1e;
$surface-light: #fbfcff;
$on-surface-light: #191c1e;
$surface-variant-light: #dde3ea;
$on-surface-variant-light: #41484d;
$outline-light: #71787e;
$inverse-on-surface-light: #f0f1f4;
$inverse-surface-light: #2e3133;


$primary-dark: #7fcfff;
$on-primary-dark: #00344c;
$primary-container-dark: #004c6d;
$on-Primary-container-dark: #c5e7ff;
$secondary-dark: #ffafcf;
$on-secondary-dark: #640039;
$secondary-container-dark: #8c0052;
$on-secondary-container-dark: #ffd8e6;
$tertiary-dark: #ccc0e9;
$on-tertiary-dark: #332b4b;
$tertiary-container-dark: #4a4263;
$on-tertiary-container-dark: #e9ddff;
$error-dark: #ffb4a9;
$error-container-dark: #930006;
$on-error-dark: #680003;
$on-error-container-dark: #ffdad4;
$background-dark: #191c1e;
$on-background-dark: #e1e2e5;
$surface-dark: #191c1e;
$on-surface-dark: #e1e2e5;
$surface-variant-dark: #41484d;
$on-surface-variant-dark: #c1c7ce;
$outline-dark: #8b9197;
$inverse-on-surface-dark: #191c1e;
$inverse-surface-dark: #e1e2e5;

$primary-main: #3377FF;
