.dfx-app {
  height: 100%;
}

body, html {
  min-height: calc(100dvh - 120px);
  height: 100%;
  margin: 0;
  overflow-wrap: break-word;
}

.body {
  height: 100dvh;
  margin: 0 !important;
  overflow: auto;
}

elab-generic-wrapper {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
}


:host ::ng-deep .custom-snackbar {
  .info-light {
    border-radius: 10px !important;
    border: 1px solid #0674C6 !important;
    background: rgba(255, 255, 255, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  .info-dark {
    border-radius: 10px !important;
    border: 1px solid #0674C6 !important;
    background: rgba(77, 77, 77, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  .success-light {
    border-radius: 10px !important;
    border: 1px solid #00B00C !important;
    background: rgba(255, 255, 255, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  .success-dark {
    border-radius: 10px !important;
    border: 1px solid #00B00C !important;
    background: rgba(77, 77, 77, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  .error-light {
    border-radius: 10px !important;
    border: 1px solid #B00020 !important;
    background: rgba(255, 255, 255, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  .error-dark {
    border-radius: 10px !important;
    border: 1px solid #B00020 !important;
    background: rgba(77, 77, 77, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  
}

.sub-text {
  color: #808080;
}

.sub-text-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.37px;
  color: #808080;
}

.error-box-x {
  
}

.small-icon {
  font-size: 16px !important;
  height: 16px !important;
  width: 16px !important;
}

h2 {
  margin-block-start: 0em !important;
  margin-block-end: 40px !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.full-view {
  width: 100%;
}

.full-sidenav-view {
  margin: 0 auto;
  padding: 0 0 0 60px;
  width: 100%;
}

.full-form-view {
  width: 100%;
}





::ng-deep .mat-mdc-raised-button[disabled] {
  background-color: #FFD39E !important;
}

.round-panel {
  > .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 12px !important
  }
}


.overflow-panel {
  > .mat-mdc-dialog-container {
    overflow: auto;
    height: 100dvh;
  }
}

.overflow-panel-2 {
  > .mat-mdc-dialog-container {
    overflow: auto;
    height: 100dvh;
  }
}

@media only screen and (max-width: 650px) {
  .overflow-panel {
    > .mat-mdc-dialog-container {
      overflow: auto;
      height: 100dvh;
      width: 100dvw !important;
    }
  }
}
@media only screen and (min-width: 650px) {
  .overflow-panel {
    > .mat-mdc-dialog-container {
      overflow: auto;
      height: 100dvh;
      width: 650px !important;
    }
  }
  
  .overflow-panel-2 {
    > .mat-mdc-dialog-container {
      overflow: auto;
      height: 100dvh;
      width: 100dvw !important;
    }
  }
}

.custom-snackbar-80 {
  top: 80px !important; /* Stellt die Top-Position ein */
}
