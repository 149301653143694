@use '@angular/material' as mat;

$font-family: 'Nunito', sans-serif;

$typography-config: mat.define-legacy-typography-config(
  $font-family:   $font-family,
  $display-4:     mat.define-typography-level(112px, 112px, 300),
  $display-3:     mat.define-typography-level(57px, 64px, 400, $letter-spacing: -0.25px),
  $display-2:     mat.define-typography-level(45px, 52px, 400, $letter-spacing: 0px),
  $display-1:     mat.define-typography-level(36px, 44px, 400, $letter-spacing: 0px),
  $headline:      mat.define-typography-level(24px, 32px, 400, $letter-spacing: 0px),
  $title:         mat.define-typography-level(22px, 28px, 400, $letter-spacing: 0px),
  $subheading-2:  mat.define-typography-level(16px, 24px, 500, $letter-spacing: 0.1px),
  $subheading-1:  mat.define-typography-level(14px, 20px, 500, $letter-spacing: 0.1px),
  $body-2:        mat.define-typography-level(16px, 24px, 400, $letter-spacing: 0.5px),
  $body-1:        mat.define-typography-level(14px, 20px, 400, $letter-spacing: 0.25px),
  $caption:       mat.define-typography-level(12px, 16px, 400, $letter-spacing: 0.4px),
  $button:        mat.define-typography-level(14px, 20px, 500, $letter-spacing: 0.1px),
    // Line-height must be unit-less fraction of the font-size.
  $input:         mat.define-typography-level(16px, 1.125, 400)
);

