//@use 'libs/frontend/kw-stylings/src/index';

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}

.spinner-big:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -10px;
  margin-left: -20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}

.header {
  display: none !important;
}

.mdc-snackbar__surface {
  text-align: center;
}

::ng-deep .custom-snackbar {
  .info-light {
    border-radius: 10px !important;
    border: 1px solid #0674C6 !important;
    background: rgba(255, 255, 255, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  .info-dark {
    border-radius: 10px !important;
    border: 1px solid #0674C6 !important;
    background: rgba(77, 77, 77, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  ::ng-deep.success-light {
    border-radius: 10px !important;
    border: 1px solid #00B00C !important;
    background: rgba(255, 255, 255, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  .success-dark {
    border-radius: 10px !important;
    border: 1px solid #00B00C !important;
    background: rgba(77, 77, 77, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  .error-light {
    border-radius: 10px !important;
    border: 1px solid #B00020 !important;
    background: rgba(255, 255, 255, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  .error-dark {
    border-radius: 10px !important;
    border: 1px solid #B00020 !important;
    background: rgba(77, 77, 77, 0.80) !important;
    backdrop-filter: blur(32.5px) !important;
  }
  
  
}

::ng-deep .success-light {
  border-radius: 10px !important;
  border: 1px solid #00B00C !important;
  background: rgba(255, 255, 255, 0.80) !important;
  backdrop-filter: blur(32.5px) !important;
}

.link {
  cursor: pointer;
  color: #009BDFFF;
  :hover {
    color: #009BDFFF;
  }
}


.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

/* Überschreiben des Autofill-Hintergrundes für Input-Felder */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: [Ihre Textfarbe hier] !important;
}

input:-moz-autofill,
input:-moz-autofill:hover,
input:-moz-autofill:focus,
input:-moz-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  text-fill-color: [Ihre Textfarbe hier] !important;
}

.non-selectable {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Nicht-präfixierte Version, funktioniert in den meisten modernen Browsern */
}

.custom-snackbar-80 {
  top: 80px !important; /* Stellt die Top-Position ein */
}

body {
  -webkit-font-smoothing: antialiased;
}
