@import 'assets/scss/text.scss';
@import 'node_modules/quill/dist/quill.snow.css';

html,
body {
  position: relative;
  top: 0 !important;
  left: 0 !important;
  height: 100%;
  overflow: overlay;
  overflow-x: overlay;
  background: #ffffff;
}

body {
  margin: 0;
}

mat-card {
  border-radius: 0 !important;
}

@media only screen and (max-width: 600px) {
  input[type='text'] {
    font-size: 16px !important;
  }
}

.relative {
  position: relative;
}

.container-default {
  max-width: 1200px;
  padding: 0 15px;
}

.w-100-children > * {
  width: 100% !important;
}

.w-50-children > * {
  width: 50% !important;
}

.px-20 {
  padding: 0 20px !important;
}

.my-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-20 {
  margin: 20px 0;
}

.vSub {
  vertical-align: sub;
}

.br-20 .mat-mdc-dialog-container {
  border-radius: 20px !important;
}
.br-20 {
  border-radius: 20px !important;
}

.br-10 {
  border-radius: 10px !important;
}

.mat-mdc-form-field {
  font-size: 14px;
}

.mat-mdc-select-search-inner.mat-mdc-typography.mat-mdc-datepicker-content.mat-mdc-tab-header {
  margin-top: 35px !important;
}

.mat-mdc-select-panel-wrap {
  margin-top: 2rem;
}

.my-10 {
  margin: 10px 0 !important;
}

.p-10 {
  padding: 10px;
}

.pl-60 {
  padding-left: 60px;
}

.p-40 {
  padding: 40px;
} 

image-cropper {
  max-height: 460px !important;
}

.ellipsis-1 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-break: auto !important;
  display: -webkit-box !important;
  word-wrap: break-word !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}

.ellipsis-2 {
  overflow: hidden !important;
  word-break: break-all !important;
  text-overflow: ellipsis !important;
  line-break: auto !important;
  display: -webkit-box !important;
  word-wrap: break-word !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.cropper,
.cropper > .move {
  border: 1px solid #3377FF !important;
}

.cropper > .move {
  display: flex;
  flex-direction: column;
  place-content: center;
  justify-content: center;
  align-items: center;
}

.cropper > span > .square {
  display: none !important;
}

.ellipsis-3 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-break: auto !important;
  display: -webkit-box !important;
  word-wrap: break-word !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}

.ellipsis-4 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-break: auto !important;
  display: -webkit-box !important;
  word-wrap: break-word !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
}

mat-chip-option{
  opacity: 1 !important;
}


.m-0 {
  margin: 0;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.w-25 {
  width: 25%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100% !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-wrap {
  display: flex;
  flex-flow: row wrap;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.align-end-start {
  display: flex;
  justify-content: flex-end;
  align-items: start;
}

.align-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.align-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.align-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.align-center-end {
  display: flex;
  justify-content: center;
  align-items: end;
}

.align-space-between {
  display: flex;
  justify-content: space-between;
}

.align-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.align-space-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: end;
}

.no-border {
  border: none !important;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px !important;
}

.gap-25 {
  gap: 25px !important;
}

.gap-30 {
  gap: 30px !important;
}

.gap-40 {
  gap: 40px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row-start-center {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.flex-row-end-center {
  display: flex !important;
  flex-direction: row !important;       
  justify-content: flex-end !important; 
  align-items: center !important;       
}

.flex-column-center {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.flex-row-center {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.flex-column-center-center {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-row-center-center {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-row-center-start {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: start !important;
}

.flex-column-center-start {
  display: flex;
  flex-direction: column;       
  align-items: center;          
  justify-content: flex-start;  
}

.flex-column-center-end {
  display: flex;
  flex-direction: column;       
  align-items: center;          
  justify-content: flex-end;  
}

.flex-row-wrap-space-between {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.gray-color {
  color: #4D4D4D;
}

.custom-tooltip {
  position: relative !important;
  flex-direction: column !important;
  justify-content: center !important;
  display: flex !important;
  width: fit-content !important;
  width: -moz-fit-content !important;
  height: 100% !important;
  background-color: white !important;
  z-index: 100 !important;
  padding: 0.5rem !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
}

.tooltip {
  position: relative;
  left: 60px !important;
  height: 100%;
  bottom: 50px;  
}

html .no-padding-input .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}

.p-absolute {
  position: absolute;
}

.pg-5 {
  padding-bottom: 5px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-10 {
  right: 10px;
}

.pr-20 {
  right: 20px;
}

.pl-40 {
  padding-left: 40px;
}

.p-50 {
  padding: 50px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.py-20 {
  padding: 20px 0;
}

.text-center {
  text-align: center;
}

//.active-link,
//.active-link > mat-icon {
//  color: #3377FF !important;
//}

.inactive-link {
  background-color: #fafafa;
}

.bg-dark-white {
  background-color: #eff2f3;
}

.round-corners {
  border-radius: 10px !important;
}

.hide-on-mobile {
  @media (max-width: 600px) {
    display: none !important;
  }
}

.hide-on-tablet {
  @media (max-width: 960px) {
    display: none !important;
  }
}

.hide-on-web {
  @media (min-width: 600px) {
    display: none !important;
  }
}

.hide {
  display: none !important;
}

.flex-direction-swap-column-to-row {
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
  }
}

.flex-direction-swap-row-to-column {
  display: flex;
  flex-direction: row;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.no-shadow-mat-card {
  box-shadow: none !important;
  border: 1px solid #4d4d4d1f;
}

.round-corner-top-left {
  border-top-left-radius: 10px !important;
}

.round-corner-top-right {
  border-top-right-radius: 10px !important;
}

.round-corner-bottom-left {
  border-bottom-left-radius: 10px !important;
}

.round-corner-bottom-right {
  border-bottom-right-radius: 10px !important;
}

df-header {
  width: 100%;
  z-index: 999;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-12 {
  border-radius: 12px !important;
}

.border-radius-10-without-shadow {
  border-radius: 10px !important;
  box-shadow: none;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.font-size-14 {
  font-size: 14px;
}

.p-0 {
  padding: 0 !important;
}

.link {
  cursor: pointer;
  color: #3377FF;
  :hover {
    color: #3377FF;
  }
}

.step-body-container {
  padding: 0 40px 10px 20px;
}

.step-body-title {
  margin-top: 0;
  margin-bottom: 0;
}

.followed {
  color: gold !important;
}

// Font Size
.fs24 {
  font-size: 24px;
}

.fs20 {
  font-size: 20px;
}

.fs18 {
  font-size: 18px;
  line-height: 27px;
}
.fs16 {
  font-size: 16px;
  line-height: 24px;
  word-wrap: break-word;
}

.fs14 {
  font-size: 14px;
  line-height: 21px;
}

.fs12 {
  font-size: 12px;
  line-height: 16px;
}

.mat-mdc-tab-label-active {
  color: #3377FF !important;
  font-weight: bold !important;
}

// Changing the color of the mat slide toggle is a known problem (see: https://github.com/angular/components/issues/26818)

.mat-mdc-slide-toggle {
  --mdc-switch-selected-handle-color: #3377FF;
  --mdc-switch-selected-pressed-handle-color: #3377FF;
  --mdc-switch-selected-pressed-state-layer-color: #3377FF;
  --mdc-switch-selected-hover-state-layer-color: #3377FF;
  --mdc-switch-selected-hover-handle-color: #3377FF;
  --mdc-switch-selected-focus-state-layer-color: #3377FF;
  --mdc-switch-selected-focus-handle-color: #3377FF;
  // opacity did not work, so I used a different, lighter color for now (#95C9DF)
  --mdc-switch-selected-track-color: #95C9DF;
  --mdc-switch-selected-pressed-track-color: #95C9DF;
  --mdc-switch-selected-hover-track-color: #95C9DF;
  --mdc-switch-selected-focus-track-color: #95C9DF;
}

//Color effects

.bg-glassmorph .mat-mdc-dialog-container {
  background: rgba(255, 255, 255, 0.8) !important;
  -webkit-backdrop-filter: blur(25px) !important;
  backdrop-filter: blur(25px) !important;
}
.bg-glassmorph {
  background: rgba(255, 255, 255, 0.8) !important;
  -webkit-backdrop-filter: blur(25px) !important;
  backdrop-filter: blur(25px) !important;
  border: 1px solid #e4e4e4 !important;
}

.bg-glassmorph-wo-color {
  -webkit-backdrop-filter: blur(25px) !important;
  backdrop-filter: blur(25px) !important;
  border: 1px solid #e4e4e4 !important;
}
.bg-glassmorph-wo-color:hover {
  background-color: #381c2c;
}

//tagged content style with angular-mentions (@xyz)
.chipTag {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border: 1px #e0e0e0 solid;
  border-radius: 24px;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 8px;
  color: #000000de;
  margin-right: 2px;
  vertical-align: middle;
  a {
    color: inherit;
    text-decoration: none;
  }
  .material-icons-round {
    font-size: 30px;
  }
}

.simpleTag {
  color: #3377FF;
  position: relative;
  display: inline;

  text-decoration: none;
  .tagPopup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    visibility: hidden;
    padding: 12px;
    position: absolute;
    z-index: 1;
    left: 0%;
    top: 100%;
    width: 300px;
    height: 100px; //160px with button
    color: black;
    opacity: 0;
    transition: visibility ease-in 0.2s, opacity ease-in 0.2s;
    .material-icons-round {
      font-size: 60px;
      width: 60px;
      height: 60px;
    }
    .image-row {
      display: flex;
      flex-direction: row;
      gap: 10px;
      height: -webkit-fill-available;
      width: 90%;
      align-items: center;
      .name-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 75%;
        .studio-name {
          margin-top: auto;
        }
        .user-name {
          margin-top: auto;
          margin-bottom: auto;
        }
        .studio-underline {
          color: #6b6b6b;
          font-size: 12px;
          margin-bottom: auto;
          margin-top: none;
        }
        .follow-row {
          margin-top: auto;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .follow-info-element {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            :first-child {
              color: #6b6b6b;
              font-size: 12px;
            }
          }
        }
      }
    }
    .vp-button {
      margin-top: auto;
      background-color: transparent !important;
      color: #3377FF !important;
      box-shadow: none !important;
      border: #3377FF 1px solid;
      border-radius: 10px;
      padding: 12px;
      width: 95%;
      text-decoration: none;
      font-weight: bold;
      display: flex;
      justify-content: center;
    }
  }
  a {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    color: inherit;
    text-decoration: none;
    .material-icons-round {
      font-size: inherit;
    }
  }
}
.simpleTag:hover .tagPopup {
  visibility: visible;
  opacity: 1;
  transition: visibility ease-in 0.2s, opacity ease-in 0.2s;
}

.ql-editor {
  min-height: 150px;
}

.hide-p-margin {
  p {
    margin: 0;
  }
}

//mention-menu style
.ql-mention-list-container {
  z-index: 100;
}

.mention-menu {
  background: rgba(255, 255, 255, 0.5) !important;
  -webkit-backdrop-filter: blur(25px) !important;
  backdrop-filter: blur(25px) !important;
  border: 1px solid #e4e4e4 !important;
  border-radius: 14px !important;
  padding: 0 !important;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  .selected {
    background-color: rgba(200, 200, 200, 0.85) !important;
  }
  .mention-item {
    padding: 0.75em 1.5em !important;
    width: calc(100% + 5px);
    overflow-x: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    .material-icons-round {
      width: 30px;
      height: 30px;
      font-size: 30px;
    }
  }
}
/* width */
.mention-menu::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.xng-breadcrumb-root .xng-breadcrumb-item:last-of-type {
  .xng-breadcrumb-trail {
    color: #3377FF;
  }
}

// **Auth dialogs**

.auth-mat {
  border-radius: 15px !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .mat-mdc-dialog-container {
    width: 400px;
    border-radius: 15px !important;
    -webkit-backdrop-filter: blur(25px) !important;
    backdrop-filter: blur(25px) !important;
  }
}

.auth-button {
  border-radius: 25px !important;
  height: 50px;
}

.auth-button-outline {
  border-radius: 25px !important;
  border-color: #3377FF !important;
  height: 50px;
  font-weight: bold;
}
.auth-title-container {
  margin-left: -23px;
  .auth-title {
    color: #4d4d4d;
    font-weight: bold;
    text-align: left;
  }
}

.auth-a {
  color: #3377FF;
  text-decoration: none;
  cursor: pointer;
}

.styledTooltip {
  background-color: white !important;
  color: #4d4d4d !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 15px !important;
  font-size: 15px !important;
  line-height: 22px !important;
  padding: 25px 20px !important;
  max-width: 315px !important;
}

.df-link {
  color: #3377FF !important; 
  cursor:pointer;
}
