/* general */
.action-section {
  margin: 10px 0;
}

/* SKILL */
.skill-box {
  width: auto;
  min-width: 100px;
  margin-bottom: 10px;
  margin-left: 4px;
  padding: 2px 8px 8px 8px;
  border: 2px solid #b7b7b7;
  border-radius: 8px;

  &.selected {
    border-color: #12cfeb;
  }

  &.add {
    background-color: #dedede;
  }

  .add-icon {
    font-size: 50px;
    height: 43px;
    width: 55px;
  }
}

.skill-bar-outside {
  height: 12px;
  width: 100%;
  border: 2px solid #b7b7b7c2;
  border-radius: 15px;
}

.skill-bar-inside {
  height: 100%;
  border-radius: 15px;
  background-color: #12cfeb;
}

.edit-button-right {
  position: relative;
}

.edit-button-right > span {
  position: absolute;
  right: 20px;
  top: 4px;
}

.edit-button-left {
  position: relative;
}

.edit-button-left > span {
  transition: all 0.8s;
  position: absolute;
  left: -50px;
  top: -4px;
}
