@use '@angular/material' as mat;
@use 'sass:map';

@import './colors';
@import './typography';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-mdc-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($typography-config);`
@include mat.all-component-typographies($typography-config);
@include mat.core();

$primary: (
  Main: $primary-light,
  Light: $primary-light,
  Dark: $primary-dark,
  contrast: (
    Main: #ffffff,
    Light: $on-primary-light,
    Dark: $on-primary-dark,
  ),
);

$accent: (
  Main: #e33691,
  Light: $secondary-light,
  Dark: $secondary-dark,
  contrast: (
    Main: #ffffff,
    Light: $on-secondary-light,
    Dark: $on-secondary-dark,
  ),
);

$warn: (
  Main: #b00020,
  Light: $error-light,
  Dark: $error-dark,
  contrast: (
    Main: #ffffff,
    Light: $on-error-light,
    Dark: $on-error-dark,
  ),
);

$tertiary: (
  Main: $tertiary-light,
  Light: $tertiary-light,
  Dark: $tertiary-dark,
  contrast: (
    Main: $on-tertiary-light,
    Light: $on-tertiary-light,
    Dark: $on-tertiary-dark,
  ),
);

$background: (
  Main: $background-light,
  Light: $background-light,
  Dark: $background-dark,
  contrast: (
    Main: $on-background-light,
    Light: $on-background-light,
    Dark: $on-background-dark,
  ),
);

$surface: (
  Main: $surface-light,
  Light: $surface-light,
  Dark: $surface-dark,
  contrast: (
    Main: $on-surface-light,
    Light: $on-surface-light,
    Dark: $on-surface-dark,
  ),
);

$surface-variant: (
  Main: $surface-variant-light,
  Light: $surface-variant-light,
  Dark: $surface-variant-dark,
  contrast: (
    Main: $on-surface-variant-light,
    Light: $on-surface-variant-light,
    Dark: $on-surface-variant-dark,
  ),
);

$danceflavors-primary: mat.define-palette($primary, Main, Light, Dark);
$danceflavors-accent: mat.define-palette($accent, Main, Light, Dark);
$danceflavors-warn: mat.define-palette($warn, Main, Light, Dark);
$danceflavors-tertiary: mat.define-palette($tertiary, Main, Light, Dark);
$danceflavors-background: mat.define-palette($background, Main, Light, Dark);
$danceflavors-surface: mat.define-palette($surface, Main, Light, Dark);
$danceflavors-surface-variant: mat.define-palette(
  $surface-variant,
  Main,
  Light,
  Dark
);

$danceflavors-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $danceflavors-primary,
      accent: $danceflavors-accent,
      warn: $danceflavors-warn,
    ),
  )
);
$danceflavors-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $danceflavors-primary,
      accent: $danceflavors-accent,
      warn: $danceflavors-warn,
    ),
  )
);

@function my-mat-light-theme-foreground($color) {
  @return (
    base: $color,
    divider: rgba(black, 0.12),
    dividers: rgba(black, 0.12),
    disabled: rgba($color, 0.38),
    disabled-button: rgba($color, 0.38),
    disabled-text: rgba($color, 0.38),
    elevation: $color,
    hint-text: rgba($color, 0.38),
    //secondary-text:    rgba($color, 0.54),
    //icon:              rgba($color, 0.54),
    //icons:             rgba($color, 0.54),
    //text:              rgba($color, 0.87),
    secondary-text: $color,
    icon: $color,
    icons: $color,
    text: $color,
    slider-min: rgba($color, 0.87),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38)
  );
}

@function my-mat-light-theme-background($color) {
  @return (
    status-bar: #e0e0e0,
    app-bar: $color,
    background: #ffffff,
    hover: rgba(0, 0, 0, 0.04),
    card: white,
    dialog: white,
    disabled-button: rgba(0, 0, 0, 0.12),
    raised-button: white,
    focused-button: rgba(0, 0, 0, 0.12),
    selected-button: #e0e0e0,
    selected-disabled-button: #bdbdbd,
    disabled-button-toggle: #eeeeee,
    unselected-chip: #e0e0e0,
    disabled-list-option: #eeeeee,
    tooltip: #616161
  );
}

$df-light-theme-foreground: my-mat-light-theme-foreground(#4d4d4d);
$df-light-theme-background: my-mat-light-theme-background(white);

$df-light-theme-custom: map.merge(
  $danceflavors-light-theme,
  (
    color: (
      primary: map.get($danceflavors-light-theme, primary),
      accent: map.get($danceflavors-light-theme, accent),
      warn: map.get($danceflavors-light-theme, warn),
      foreground: $df-light-theme-foreground,
      background: $df-light-theme-background,
    ),
    foreground: $df-light-theme-foreground,
    background: $df-light-theme-background,
  )
);

.df-light-theme {
  color: #4d4d4d;
  font-family: $font-family;
  //@include mat.all-component-themes($df-light-theme-custom);

  .mat-mdc-tertiary {
    background-color: $tertiary-light;
    color: $on-tertiary-light;
  }

  .mat-mdc-background {
    background-color: $background-light;
    color: $on-background-light;
  }

  .mat-mdc-surface {
    background-color: $surface-light;
    color: $on-surface-light;
  }

  .mat-mdc-surface-variant {
    background-color: $surface-variant-light;
    color: $on-surface-variant-light;
  }
}

.df-dark-theme {
  background-color: #252835;
  color: #afb1be;
  font-family: $font-family;
  //@include mat.all-component-themes($danceflavors-dark-theme);

  .mat-mdc-tertiary {
    background-color: $tertiary-dark;
    color: $on-tertiary-dark;
  }

  .mat-mdc-background {
    background-color: $background-dark;
    color: $on-background-dark;
  }

  .mat-mdc-surface {
    background-color: $surface-dark;
    color: $on-surface-dark;
  }

  .mat-mdc-surface-variant {
    background-color: $surface-variant-dark;
    color: $on-surface-variant-dark;
  }
}

.df-dark-theme .bg-dark {
  background-color: #252835;
  color: #afb1be;
}

.send-message .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}

.mat-mdc-menu-panel.share-menu-customized {
  min-width: 300px !important;
}

df-shared-lib-phone-link {
  height: 44px !important;
}

.fontSize14 {
  font-size: 14px !important;
}

df-shared-lib-email-link {
  height: 44px !important;
}

@include mat.core-theme($danceflavors-light-theme);
@include mat.button-theme($danceflavors-light-theme);
@include mat.all-component-themes($danceflavors-light-theme);

@import './assets/main';
@import './assets/scss/profile.scss';

@import 'node_modules/basscss-type-scale/index.css';
@import 'node_modules/basscss-typography/index.css';
@import 'node_modules/basscss-layout/index.css';
@import 'node_modules/basscss-align/index.css';
@import 'node_modules/basscss-margin/index.css';
@import 'node_modules/basscss-padding/index.css';
@import 'node_modules/basscss-position/index.css';
@import 'node_modules/basscss-border/index.css';
@import 'node_modules/basscss-hide/index.css';
